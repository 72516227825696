export default {
    data() {
        return {
            socketHandlers: {},
        }
    },
    watch: {
        ['$store.state.map.liveUpdatesEnabled'](liveUpdatesEnabled) {
            this.clearSocketHandlers()
            if (liveUpdatesEnabled) {
                this.addSocketHandlers()
            }
        },
    },
    mounted() {
        Object.keys(this.$options?.sockets ?? {}).forEach(event => {
            this.socketHandlers[event] = this.$options.sockets[event].bind(this)
        })
        this.addSocketHandlers()
    },
    beforeDestroy() {
        this.clearSocketHandlers()
    },
    methods: {
        addSocketHandlers() {
            Object.keys(this.socketHandlers).forEach(event => {
                this.$socket?.on(event, this.socketHandlers[event])
            })
        },
        clearSocketHandlers() {
            Object.keys(this.socketHandlers).forEach(event => {
                this.$socket?.off(event, this.socketHandlers[event])
            })
        },
    },
}
